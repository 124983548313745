    .TeamMember {
        padding: 0 8%;
    }

    .member__Img {
        display: flex;
        justify-content: center;  
        padding-top: 5%;
    }

    .member__Img > img {
        width: 100%;
        height: auto;
    }

    .member__Name, 
    .member__Bio,
    .member__Title {
        text-align: center;
    }

    .button__Container {
        display: flex;
        justify-content: space-evenly;
    }
    
    .button__Container > div > a > button {
        background-color: #5E8B7E;
        color: #f2f2f2;
        padding: 10px 50px;
        border-radius: 10px;
    }

    .button__Container > a > button {
        background-color: #163C5E;
        color: #f2f2f2;
        padding: 10px 25px;
        border-radius: 10px;
    }

    .button__Container > div > a > button,
    .button__Container > a > button {
        display: flex;
        align-items: center;
    }

    .button-style {
        text-decoration: none;
    }

    @media (min-width: 1000px) {
        .TeamMember {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 30px;
            grid-row-gap: 0px;
            }
            
            .div1 { grid-area: 1 / 1 / 2 / 3; }
            .div2 { grid-area: 1 / 1 / 2 / 2; }
            .div3 { grid-area: 1 / 2 / 2 / 3; }

            .member__Img > img {
                width: 100%;      /* This will make sure the image takes up 100% of the parent container's width */
                height: auto;     /* This will maintain the aspect ratio of the image */
                object-fit: cover; /* This will make sure the image covers the area of the container without stretching */
            }
            
    }
    