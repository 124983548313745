@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

body {
  font-family: 'Playfair Display', serif;
}

body {
  background-color: #f2f2f2;
}

button {
  cursor: pointer;
}

h1, h2, h3,
h4, h5, h6 {
  color: #0074B7;
}