.Footer {
    background-color: #333;
}

.Footer > .logo {
    text-align: center;
    color: #f2f2f2;
    padding-top: 25px;
    display: flex;
    justify-content: center;
}

.footer__header {
    color: #ffd700;
}

.location > h5 {
    color: #f2f2f2;
}

.pages > .footer-link,
.privacy_and_legal > .footer-link {
    color: #f2f2f2;
    text-decoration: none;
    margin-bottom: 5px;
}

.pages > a, .privacy_and_legal {
    display: grid;
    grid-template-columns: 1fr;
}

.copyright > p {
    color: #f2f2f2;
}

.link__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* grid-template-rows: ; */
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin: 0 32px;
    justify-items: center;
    }
    
.div1 { grid-area: 1 / 1 / 3 / 2; }
.div2 { grid-area: 1 / 2 / 3 / 3; }
.div3 { grid-area: 3 / 1 / 4 / 2; }
.div4 { grid-area: 3 / 2 / 4 / 3; }

.payment, .copyright {
    text-align: center;
}


.payment > img {
    width: 35%;
}

.Footer .copyright {
    padding: 20px 0; /* Add padding at the top and bottom */
}

@media (min-width: 700px) {
    .payment > img {
        width: 25%;
    }
}


@media (min-width: 1000px) {
    .Footer {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: auto auto;
        grid-gap: 20px;
        padding: 16px 32px; /* Adjust the padding as needed */
    }

    .Footer > .logo {
        grid-column: 1 / 2;
        grid-row: 1 / 3; /* Span two rows */
        justify-self: start; /* Align to the start of the grid area */
    }

    .Footer .link__container {
        grid-column: 2 / 5;
        grid-row: 1 / 3;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto auto;
        grid-gap: 20px;
    }

    .Footer .location {
        grid-area: 1 / 1 / 2 / 2;
    }

    .Footer .privacy_and_legal {
        grid-area: 2 / 1 / 3 / 2;
    }

    .Footer .pages {
        grid-area: 1 / 2 / 3 / 3; /* Span two rows */
    }

    .Footer .follow__us {
        grid-area: 1 / 3 / 2 / 4;
    }

    .Footer .payment {
        grid-column: 5 / 6;
        grid-row: 1 / 3; /* Span two rows */
        justify-self: end; /* Align to the end of the grid area */
    }

    /* Additional adjustments to the footer links if needed */
    .Footer .footer-link {
        /* Styles for footer links */
    }

    /* Adjust the copyright position if necessary */
    .Footer .copyright {
        grid-column: 1 / -1; /* Span all columns */
        justify-self: center;
    }

    .payment > img {
        width: 100%;
    }
}


@media (min-width: 1400px) {
    .Footer {
        padding: 16px 75px;
    }

    .footer__header {
        font-size: 16px;
    }

    .payment > img {
        width: 65%;
    }
}