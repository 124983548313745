.title__header {
    text-align: center;
    color: #0074B7;
    font-size: 20px;
}

.section__image, .about__button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.section__image,
.aboutUs__content > p {
    padding: 0 8%;
}

.aboutUs__content > p {
    text-align: center;
}

.section__image > img {
    width: 100%;
    height: auto;
}

.aboutUs__button {
    background-color: #163C5E;
    color: #f2f2f2;
    padding: 10px 25px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    border: none; /* To remove the default browser styling for buttons */
    text-decoration: none; /* To remove underline from text */
    font-size: 1em; /* Adjust as needed */
    text-align: center; /* To center the text inside the button */
}


.about__button {
    padding: 5% 10%;
}

@media (min-width: 700px) {
    .title__header {
        font-size: 24px;
    }

    .AboutUs > p {
        font-size: 18px;
    }

    .about__button {
        padding: 2% 10%;
    }
}

@media (min-width: 1000px) {
    .AboutUs {
        display: grid;
        grid-template-columns: 1fr 1fr; /* Two equal columns */
        grid-template-rows: auto; /* One row, height set automatically */
        grid-gap: 20px; /* Adjust the gap between columns as needed */
        padding: 0 4%;
    }

    .section__image {
        grid-column: 1 / 2; /* Span first column */
        grid-row: 1; /* Only the first row */
    }

    .aboutUs__content {
        grid-column: 2 / 3; /* Span second column */
        grid-row: 1 / -1; /* Span all rows */
    }

    /* Adjust title, paragraph, and button styling as needed */
    .title__header {
        text-align: left;
        font-size: 32px;
    }

    .title__header h3 {
        margin-top: 0;
    }

    .AboutUs p {
        text-align: left;
    }

    .about__button {
        justify-content: flex-start;
        padding: 5% 0%;
    }    

    .aboutUs__content > p {
        padding: 0 8% 0 0;
    }
}


@media (min-width: 1400px) {
    .AboutUs .title__header {
        font-size: 40px;
    }

    .aboutUs__content p {
        padding-right: 8%;
        font-size: 22px;
    }

    /* .aboutUs__button {
        width: 200px;
        text-align: center;
    } */
}