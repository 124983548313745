.FAQ {
    padding-top: 5%;
}

.faq__container {
    padding: 0 5%;
    margin-bottom: 10%;
}

.header__container {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.mirror {
    rotate: 180deg;
}

.faq__container > h3 {
    background-color: #163C5E;
    color: #ffd700;
    text-align: center;
    padding: 3.5% 10%;
}

.faq__container > p {
    text-align: center;
}

@media (min-width: 700px) {
    .faq__container {
        padding: 0 8%;
    }
}

@media (min-width: 1000px) {
    .scissor__icon {
        font-size: 40px;
    }

    .faq__container > h3 {
        font-size: 24px;
        text-align: left;
    }

    .faq__container > .header__container > h2 {
        font-size: 32px;
    }

    .faq__container > p {
        text-align: left;
    }

    .faq__container > h3 {
        padding: 2.5% 5%;
    }

    .faq__container > p {
        padding: 0 5%;
        margin-bottom: 5%;
    }
}

@media (min-width: 1400px) {
    .faq__container > h3 {
        font-size: 28px;
        padding: 1.5% 5%;
    }

    .faq__container > p {
        font-size: 24px;
    }

    
}