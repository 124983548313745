.testimonial__slide > div {
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;}

.testimonial__slide > h4 {
    margin: 0;
    margin-bottom: 2%;
}

@media (min-width: 700px) {
    .ReactSlick > h2 {
        font-size: 24px;
    }

    div > p {
        font-size: 18px;
    }
}