.Navbar {
    background-color: #333;
}

.logo > h3 {
    color: #f2f2f2;
}

.burger__menu {
    color: #ffd700;
}

.navbar__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }
    
    .navbar__container > .logo { 
        grid-area: 1 / 1 / 2 / 2; 
        padding-left: 8%;
        display: flex;
    }

    .burger__menu { 
        grid-area: 1 / 2 / 2 / 4; 
        align-self: center;
        justify-self: end;
        /* padding-right: 15%; */
        padding-right: 8%;

    }

    /* ===================================================
    ABOVE THIS IS REFERENCING THE CLOSED MENU NAV (MOBILE)
    BELOW THIS IS REFERENCING THE OPEN MENU NAV (MOBILE)
    =================================================== */

    .desktop__navlinks > .nav-item {
        color: #f2f2f2;
        text-decoration: none;
        cursor: pointer;
    }

    .desktop__navlinks > .nav-item.active {
        color: #ffd700;
    }

    .Navbar.menu-opened {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        transition: transform 0.3s ease-in-out;
        transform: translateX(0%);
      }

    .close__menu {
        color: #ffd700;
    }

    .fullscreen__navbar {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }
        
    .close__menu { 
        grid-area: 1 / 1 / 2 / 4; 
        align-self: center;
        justify-self: end;
        padding-right: 15%;
        padding-top: 5%;
    }
    .navlinks { grid-area: 2 / 2 / 7 / 3; }

    .navlinks {
        display: grid;
        grid-template-rows: repeat(5, 1fr); /* Adjust the number based on the number of links */
        gap: 10px; /* Adjust the gap between links */
        align-content: center; /* Center align the items vertically */
      }
      
      .fullscreen__navbar .navlinks > .nav-item {
        color: #f2f2f2;
        text-decoration: none;
        text-align: center;
        padding: 15% 0;
      }

      .fullscreen__navbar .navlinks > .nav-item .active {
        color: #ffd700;
    }

      .navlinks > a > button,
      .desktop__navlinks > a > button {
        background-color: #333;
        color: #f2f2f2;
        padding: 5%;
        width: 150px;
        border: 2px solid #ffd700;
        border-radius: 15px;
      }

      .logo > a {
        color: #f2f2f2;
        font-size: 24px;
        text-decoration: none;
        /* padding: 2% 0; */
        margin: 8% 0;
      }

      .desktop__navlinks {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
      }

      .desktop__navlinks > a {
        color: #f2f2f2;
        text-decoration: none;
      }
      
      @media (min-width: 1000px) {
        .desktop__navlinks > a.active {
            color: #ffd700;
        }

        .desktop__navlinks > .nav-item:hover {
            color: #ffd700;
        }

        .desktop__navlinks > a > button {
            width: 110px;
          }
        .desktop__navlinks > a > button:hover {
            color: #333;
            background-color: #f2f2f2;
        }

        .navbar__container > .logo { 
            padding-left: 10%;
        }

        .desktop__navlinks > a:last-child {
            margin-right: 5%;
        }
        
      }

    @media (min-width: 1400px) {
        .navbar__container {
            height: 100px;
            align-content: center;
        }
    }