.service{
    padding: 0 8%;
}
.service > img {
    width: 100%;
    height: auto;
}

.service > .content__container > h2,
.service > .content__container > p {
    text-align: center;
}

/* .service > Link > button {
    color: green;
} */

.service > .content__container > a > button {
    background-color: #163C5E;
    color: #f2f2f2;
    padding: 10px 25px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    border: none; /* To remove the default browser styling for buttons */
    font-size: 1em; /* Adjust as needed */
    text-align: center; /* To center the text inside the button */
}

.service > .content__container > a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5%;
}



@media (min-width: 700px) {
    .service > .content__container > h2 {
        font-size: 24px;
    }

    .service > .content__container > p {
        font-size: 18px;
    }
}

@media (min-width: 1000px) {
    .service {
        padding-bottom: 1.5%;
    }
    .service__section > .service {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr; /* One row that fills the container */
        grid-column-gap: 20px; /* Adjust gap as needed */
        align-items: center;
    }

    /* Styles for services positioned on the left (odd children) */
    .service__section > .service:nth-child(even) img {
        grid-area: 1 / 1 / 2 / 2; /* Image in the first column */
    }

    .service__section > .service:nth-child(even) > .content__container {
        grid-area: 1 / 2 / 2 / 3; /* Text content in the second column */
    }

    /* Styles for services positioned on the right (even children) */
    .service__section > .service:nth-child(odd) img {
        grid-area: 1 / 2 / 2 / 3; /* Image in the second column */
    }

    .service__section > .service:nth-child(odd) > .content__container {
        grid-area: 1 / 1 / 2 / 2; /* Text content in the first column */
    }

    .content__container > h2 {
        margin-bottom: 5%;
    }

    .service > .content__container > h2,
    .service > .content__container > p,
    .service > .content__container > a {
        text-align: left;
    }

    #Services > div > div > div > a {
        display: flex;
        justify-content: flex-start;
    }
    
    
}



@media (min-width: 1400px) {
    .service > .content__container > h2 {
        font-size: 32px;
    }

    .service > .content__container > p {
        font-size: 20px;
    }

    
}

