.html {
    font-size: 16px;
}

.Hero {
    text-align: center;
    padding: 0 8%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Hero > h3 {
    color: #0074B7;
    margin: 25px 0 15px;
    font-size: clamp(1rem, 2.5vw, 1.5rem);
}

.Hero > h1 {
    color: #333;
    margin-top: 0;
    font-size: clamp(1.5rem, 3vw, 2.5rem);
}

.hero__button {
    background-color: #163C5E;
    color: #f2f2f2;
    padding: 0.5rem 2rem;
    border-radius: 10px;
    font-size: clamp(0.8rem, 1.5vw, 1.2rem);
}


@media (min-width: 700px) {
    .Hero > h3 {
        font-size: 24px;
    } 

    .Hero > h1 {
        font-size: 32px;
    }

    .hero__button {
        font-size: 16px;
    }
}

@media (min-width: 1000px) {
    .Hero  {
        padding: 0 10%;
    }

    .hero__button {
        padding: 1.2rem 3rem; 
        font-size: clamp(.5rem, 1.5vw, 1rem); 
        border-radius: 12px; 
    }

    .hero__container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        
        }
        
        .div1 { grid-area: 1 / 1 / 2 / 3; }
        .div2 { grid-area: 1 / 1 / 2 / 2; }
        .div3 { grid-area: 1 / 2 / 2 / 3; }

        .hero__img > img {
            width: 110%;
        }

        .hero__container > .context {
            display: flex;
            flex-direction: column;
            text-align: left;
            justify-content: center;
            padding-right: 5%;
        }

        .context > h3 {
            font-size: clamp(1rem, 2.5vw, 1.5rem);
            margin-bottom: 15px;
        }

        .context > h1 {
            font-size: clamp(1.5rem, 4vw, 3rem);
            margin-top: 0;
            color: #333;
        }
}

@media (min-width: 1400px) {
    .context > h1 {
        font-size: clamp(2rem, 4.5vw, 3.5rem);
    }

    .context > h3 {
        font-size: clamp(1.5rem, 3vw, 2rem);
    }

    .hero__button {
        padding: 1.2rem 3rem; 
        font-size: clamp(1rem, 2vw, 1.4rem);
        border-radius: 12px;
    }
    
    .hero__img > img {
        width: 105%;
    }
    
    
}