.about__text{
    margin: 0 8%;
    text-align: center;
}

.AboutPage > .mobile__image__container > img {
    width: 100%;
    height: auto;
}

.mobile__image__container {
    margin: 2% 8%;
}

.about__text > p > strong {
    color: #0074B7;
    text-decoration: underline;
}

.about__text > a {
    display: flex;
    justify-content: center;
    text-decoration: none;
}

.about__text > a > button  {
    background-color: #163C5E;
    color: #f2f2f2;
    padding: 10px 25px;
    border-radius: 10px;
    display: flex;
    border: none;
    text-decoration: none;
    font-size: 1em;
    margin-bottom: 10%;
}

@media (min-width: 700px) {
    .about__text > h3 {
        font-size: 24px;
    }
}

@media (min-width: 1000px) {
    .AboutPage {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 0px;
        padding: 5% 8%;
        }
        
        .div1 { grid-area: 1 / 1 / 2 / 3; }
        .div2 { grid-area: 1 / 1 / 2 / 2; }
        .div3 { grid-area: 1 / 2 / 2 / 3; }

    .desktop__image__container {
        width: 100%;
        height: auto;
    }

    .desktop__image__container > img {
        width: 100%;
        height: auto;
        object-fit: contain; /* This will ensure the entire image is visible */
    }

    .about__text > h3 {
        font-size: 32px;
    }

}