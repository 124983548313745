.pricing__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 0px;
    margin: 0 8%;
    padding: 0 10%;
    }
    
    .div1 { grid-area: 1 / 1 / 2 / 2; }
    .div2 { grid-area: 1 / 2 / 2 / 3; }
    .div3 { grid-area: 2 / 1 / 3 / 2; }
    .div4 { grid-area: 2 / 2 / 3 / 3; }
    .div5 { grid-area: 3 / 1 / 4 / 2; }
    .div6 { grid-area: 3 / 2 / 4 / 3; }
    .div7 { grid-area: 4 / 1 / 5 / 2; }
    .div8 { grid-area: 4 / 2 / 5 / 3; }
    .div9 { grid-area: 5 / 1 / 6 / 2; }
    .div10 { grid-area: 5 / 2 / 6 / 3; }

    .Prices > h2 {
        text-align: center;
        margin-top: 15%;
    }

    .Prices > div > div > h4:nth-child(2) {
        color: #333;
    }

    .Prices > p {
        font-style: italic;
        text-align: center;
        margin-top: -10px;
    }
    .service__and__price {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @media (min-width: 1000px) {
        .Prices > h2 {
            margin-top: 2%;
        }
    }

    @media (min-width: 1250px) {
        .pricing__container {
            padding: 0 10%;
            grid-column-gap: 20%;
        }
    }