.DesktopTeam {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns layout */
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding: 5% 5% 0 5%;
}

/* Assign the category__container to the first column */
.category__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }

    .category__container > button {
        background-color: #163C5E;
        cursor: pointer;
        border-radius: 10px;
        margin-bottom: 1%;
        padding: 5%;
        font-size: 1em;
    }

.barber__TeamMembers,
.spa__TeamMembers,
.special__TeamMembers {
    grid-area: 1 / 2 / 2 / 4; /* Spans from row 1 to 2, column 2 to 4 */
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Adjust this if needed */
    grid-template-rows: repeat(2, 1fr); /* Adjust this if needed */
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.category__container > button {
    background-color: #163C5E;
    cursor: pointer;
    border-radius: 10px;
    margin-bottom: 1%;
    padding: 5%;
    font-size: 1em;
}

.category__container > button:hover {
    background-color: hsl(207, 62%, 28%);
}

.barber__TeamMembers,
.spa__TeamMembers,
.special__TeamMembers {
    padding-left: 5%;
}

.barber__TeamMembers .stylist__icon img {
    width: 90%;       /* Example: setting the width */
    height: auto;       /* Example: setting the height to auto */
}

.spa__TeamMembers {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }
    
    .div1 { grid-area: 1 / 1 / 2 / 2; }
    .div2 { grid-area: 1 / 2 / 2 / 3; }
    .div3 { grid-area: 1 / 3 / 2 / 4; }

    .spa__TeamMembers .stylist__icon img {
        width: 90%;       /* Example: setting the width */
        height: auto;       /* Example: setting the height to auto */
    }

    .special__TeamMembers {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        }
        
        .div1 { grid-area: 1 / 1 / 2 / 2; }
        .div2 { grid-area: 1 / 2 / 2 / 3; }

        .special__TeamMembers .stylist__icon img {
            width: 75%;       /* Example: setting the width */
            height: auto;       /* Example: setting the height to auto */
        }

        .special__TeamMembers > div > a {
            display: flex;
            justify-content: center;
            align-content: center;
        }

        .special__TeamMembers > div > p {
            padding-top: 2%;
        }

@media (min-width: 1000px) {
    .category__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px; /* Adjust the space between buttons */
    }
    
    .category__container > button {
        width: 80%; /* Fixed width */
        height: 60px; /* Fixed height */
        background-color: #163C5E;
        cursor: pointer;
        border-radius: 10px;
        margin-bottom: 1%;
        padding: 5%;
        font-size: 1em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        opacity: 0.5;
        color: #f2f2f2;
    }
    
    .category__container > button:hover {
        background-color: hsl(207, 62%, 28%);
    }
    
    /* Active button styles */
    .category__container > button.active {
        opacity: 1;
        color: #ffd700;
    }

    .barber__TeamMembers .stylist__icon img,
    .spa__TeamMembers .stylist__icon img,
    .special__TeamMembers .stylist__icon img {
        transition: transform 0.3s ease;
    }
    
    .barber__TeamMembers .stylist__icon:hover img,
    .spa__TeamMembers .stylist__icon:hover img,
    .special__TeamMembers .stylist__icon:hover img  {
        transform: translateY(-10px);
    }

    .DesktopTeam {
        padding-top: 5px;
    }

    .DesktopTeam__grid__instruction {
        text-align: center;
        padding-left: 30%;
        font-size: 20px;
        font-style: italic;
    }
}

@media (min-width: 1400px) {
    .category__container > button {
        height: 80px;
        font-size: 20px;
    }

    .barber__TeamMembers {
        grid-row-gap: 10px;
    }

    .barber__TeamMembers .stylist__icon img,
    .spa__TeamMembers .stylist__icon img,
    .special__TeamMembers .stylist__icon img {
        padding-bottom: 10px;
    }
}